import { Box, Center, Divider, Heading, HStack } from "@chakra-ui/react";
import React from "react";

export const Logo = () => {
  return (
    <HStack>
      <img src="images/logo-1024x1024.png" alt="" className="logo-site" />

    </HStack>
  );
};
