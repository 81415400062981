import { Box, Button, Flex, HStack, IconButton, Spacer, useColorModeValue, useDisclosure,Collapse } from "@chakra-ui/react";
import { Logo } from "../Logo";
import { ConnectWalletButton } from "../ConnectWalletButton/ConnectWalletButton";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

interface NavItemProps {
  href: string;
  children: React.ReactNode;
}

export const Nav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box as="nav" bgColor="transparent" py={4} width={"100%"} className="nav-main" >
      <Flex
        maxW="80%"
        mx="auto"
        px={4}
        align="center"
        justify="space-between"
      >
        <Logo />

        {/* Hamburger Icon */}
        <IconButton
          display={{ base: "flex", xl: "none" }}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          variant="ghost"
          aria-label="Toggle Navigation"
          onClick={isOpen ? onClose : onOpen}
        />

        {/* Links */}
        <HStack
          as="ul"
          spacing={4}
          pl={8}
          listStyleType="none"
          display={{ base: isOpen ? "flex" : "none", xl: "flex" }}
          position={{ base: "absolute", xl: "relative" }}
          flex={1} // Occupy available space
          flexDirection={{ base: "column", xl: "row" }} 
          top={{ base: "120px", xl: "initial" }} 
          left={{ base: "0", xl: "initial" }}
          justify={{ base: "flex-start", xl: "flex-start" }} 
          background={{ base: "#096d2c", xl: "transparent" }}
          align={{ base: "flex-start", xl: "center" }}
          width={{ base: "100%", xl: "auto" }}
        >
          <NavItem href="https://lbp.fund//">Home</NavItem>
          <NavItem href="https://lbp.fund/#about">About</NavItem>
          <NavItem href="https://lbp.fund/#tokenomics">Tokenomics</NavItem>
          <NavItem href="https://lbp.fund/#roadmap">Roadmap</NavItem>
          <NavItem href="https://lbp.fund/#howtobuy">How To Buy</NavItem>
          <NavItem href="https://lbp.fund/#contact">Contact</NavItem>
          
        </HStack>

        {/* Connect Wallet Button */}
        <ConnectWalletButton />
      </Flex>
    </Box>
  );
};

const NavItem = ({ href, children }: NavItemProps) => {
  return (
    <Box as="li">
      <a target="_blank" className="nav-link-custom" href={href}>{children}</a>
    </Box>
  );
};
