import { Button } from "@chakra-ui/react";
import { shortenAddress, useEthers } from "@usedapp/core";
import React from "react";

export const ConnectWalletButton = () => {
  const { account, chainId, activateBrowserWallet } = useEthers();
  return (
    <Button
      borderRadius="xl"
      fontFamily={"'Boogaloo', sans-serif"}
      fontSize={"28px"}
      color={"white"}
      className="connect-wallet-button"
      onClick={!account ? activateBrowserWallet : () => {}}
    >
      {account ? shortenAddress(account) : "Connect Wallet"}
    </Button>
  );
};
