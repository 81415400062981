import { useCall, useEthers } from "@usedapp/core";
import { Contract, ethers,BigNumber } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { useSupportedNetworks } from "../constants/SupportedNetworks";
import {PresaleABI} from "./PresaleABI";
import { formatUnits } from "ethers/lib/utils";
export const useCallHook = (
  functionName: string,
  arg: any[]
): BigNumber[] | undefined => {
  const { chainId } = useEthers();
  const currentNetwork = useSupportedNetworks[chainId!];

  const { value, error } =
    useCall(
      currentNetwork?.presaleAddress && {
        contract: currentNetwork?.presaleContractInterface,
        method: functionName,
        args: arg ?? [],
      }
    ) ?? {};
  if (error) {
    console.error("Presale Hook Errors", error.message);
  }

  return value;
};

export const usePresalePrice = () => {
  const value: BigNumber[] | undefined = useCallHook("getPrice", []);
  const valueFormatted = value ? Number(formatEther(value?.[0])) : 0;
  return valueFormatted;
};

// export const useBNBcollected =( ) => {
//   const value: BigNumber[] | undefined = useCallHook("getPresaleAnalytics", []);
//   const valueFormatted = value ? Number(formatEther(value?.[0])).toFixed(4) : 0;
//   return valueFormatted;
// }

export const useBNBcollected =  async (): Promise<BigNumber | undefined> => {
  const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org/");

  const contractAddress = "0xacB5612fBB7DcFbbC9abD7aF035eD2783577B43C";

  try {
    

    const contract = new ethers.Contract(contractAddress, PresaleABI, provider);
    const [ethCollected] = await contract.getPresaleAnalytics();
    return ethCollected;
  } catch (error) {
    console.error("Presale Analytics Error", error);
    return undefined;
  }
};