import { BSC, BSCTestnet,Goerli,Mainnet } from "@usedapp/core";
import { Contract } from "ethers";

import {PresaleABI} from "./PresaleABI";

export const TokenName = "Little Baby Pepe";
export const TokenSymbol = "LBP";

export const useSupportedNetworks = {
  // BSC MAINNET
  [BSC.chainId]: {
    token: {
      contractAddress: "0x3d3432fDf03562464129257aa4F15242444cCd3B",
      name: TokenName,
      symbol: TokenSymbol,
      decimals: 18,
    },
    native: {
      contractAddress: "",
      name: BSC?.nativeCurrency?.name,
      symbol: BSC?.nativeCurrency?.symbol,
      decimals: BSC?.nativeCurrency?.decimals,
    },
    presaleAddress: "0xacB5612fBB7DcFbbC9abD7aF035eD2783577B43C",
    presaleContractInterface: new Contract(
      "0xacB5612fBB7DcFbbC9abD7aF035eD2783577B43C",
      PresaleABI
    ),
    explorerLink: {
      name: "bscscan.com",
    },
    nativeFunctions: BSC,
  },
  // [BSCTestnet.chainId]: {
  //   token: {
  //     contractAddress: "0x62861Bb6C5a9a17fF65A31dc073445789e1793B4",
  //     name: TokenName,
  //     symbol: TokenSymbol,
  //     decimals: 18,
  //   },
  //   native: {
  //     contractAddress: "",
  //     name: Goerli?.nativeCurrency?.name,
  //     symbol: Goerli?.nativeCurrency?.symbol,
  //     decimals: Goerli?.nativeCurrency?.decimals,
  //   },
  //   presaleAddress: "0xc92a2cc4CfEE10F3Fb9628bc627861EeE61458Fb",
  //   presaleContractInterface: new Contract(
  //     "0xc92a2cc4CfEE10F3Fb9628bc627861EeE61458Fb",
  //     PresaleABI
  //   ),
  //   explorerLink: {
  //     name: "https://testnet.bscscan.com/",
  //   },
  //   nativeFunctions: Goerli,
  // },
};
