import React, { useState, useEffect } from "react";
import {
  Box,
  ChakraProvider,
  Heading,
  theme,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { Footer, Nav, SwapUI } from "./components";
import HowToBuy from "./components/HowToBuy";
import { TokenInfo } from "./components/TokenInfo";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex
} from "@chakra-ui/react";
import "./App.css";

// const Countdown = () => {
//   const [remainingTime, setRemainingTime] = useState("");
//   useEffect(() => {
//     const countdownDate = new Date("2023-11-23T00:00:00Z").getTime(); // Set your countdown date and time here in UTC format
//     const interval = setInterval(() => {
//       const now = new Date().getTime();
//       const distance = countdownDate - now;

//       if (distance < 0) {
//         clearInterval(interval);
//         setRemainingTime("Countdown Expired");
//       } else {
//         const days = Math.floor(distance / (1000 * 60 * 60 * 24));
//         const hours = Math.floor(
//           (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
//         );
//         const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//         const seconds = Math.floor((distance % (1000 * 60)) / 1000);

//         setRemainingTime(
//           `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`
//         );
//       }
//     }, 1000);

//     return () => {
//       clearInterval(interval);
//     };
//   }, []);

//   const [days, hours, minutes, seconds] = remainingTime
//     .split(",")
//     .map((item) => item.trim().split(" ")[0]);

//   return (
//     <Box textAlign="center" pb={"30px"}>
//       <Heading as="h2" fontSize={{ base: "20px", md: "24px" }} mb={4}>
//         Buy now! Presale ends in:
//       </Heading>
//       <Box display="flex" justifyContent="space-between">
//         <Box
//           borderRadius="md"
//           bg="#00002e"
//           color="#90cdf4"
//           p={2}
//           textAlign="center"
//           flex="1"
//           mx={2}
//           borderWidth="1px"
//           borderColor="#90cdf4"
          
//           minWidth={{ base: "75px", md: "85px" }}
//         >
//           <Heading as="h3" fontSize={{ base: "14px", md: "16px" }}>
//             Days
//           </Heading>
//           <Heading as="h4" fontSize={{ base: "20px", md: "24px" }}>
//             {days}
//           </Heading>
//         </Box>
//         <Box
//           borderRadius="md"
//           bg="#00002e"
//           color="#90cdf4"
//           p={2}
//           textAlign="center"
//           flex="1"
//           mx={2}
//           borderWidth="1px"
//           borderColor="#90cdf4"
//           minWidth={{ base: "75px", md: "85px" }}
//         >
//           <Heading as="h3" fontSize={{ base: "14px", md: "16px" }}>
//             Hours
//           </Heading>
//           <Heading as="h4" fontSize={{ base: "20px", md: "24px" }}>
//             {hours}
//           </Heading>
//         </Box>
//         <Box
//           borderRadius="md"
//           bg="#00002e"
//           color="#90cdf4"
//           p={2}
//           textAlign="center"
//           flex="1"
//           mx={2}
//           borderWidth="1px"
//           borderColor="#90cdf4"
//           minWidth={{ base: "75px", md: "85px" }}
//         >
//           <Heading as="h3" fontSize={{ base: "14px", md: "16px" }}>
//             Minutes
//           </Heading>
//           <Heading as="h4" fontSize={{ base: "20px", md: "24px" }}>
//             {minutes}
//           </Heading>
//         </Box>
//         <Box
//           borderRadius="md"
//           bg="#00002e"
//           color="#90cdf4"
//           p={2}
//           textAlign="center"
//           flex="1"
//           mx={2}
//           borderWidth="1px"
//           borderColor="#90cdf4"
//           minWidth={{ base: "75px", md: "85px" }}
//         >
//           <Heading as="h3" fontSize={{ base: "14px", md: "16px" }}>
//             Seconds
//           </Heading>
//           <Heading as="h4" fontSize={{ base: "20px", md: "24px" }}>
//             {seconds}
//           </Heading>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

export const App = () => (
  <div>
  <VStack w="full" className="main-body">
    <div className="inner-overlay"></div>
    <Nav />
    <VStack w="80%" pt={20} pb={28}>
    <Flex justify="center" direction={{ base: "column", lg: "row" }}>
              <Box mb={{ base: 10, lg: 0 }} mr={{ base: 0, lg: 4 }}>
                <SwapUI />
              </Box>
              <Box ml={{ base: 0, lg: 4 }}>
                <HowToBuy />
              </Box>
            </Flex>
      {/* <Countdown /> */}
     
  
    </VStack>

    
  </VStack>
  <Footer />
  </div>
  
);
