import React from 'react';
import { Box, Flex, Image, Text,Heading } from '@chakra-ui/react';

export const HowToBuy = () => {
  return (
    <Box className='htb-section'>
        <Heading as="h2" size="lg" mb={4}>
      How To Buy?
    </Heading>
      <Flex align="center" mb={4}>
        <Box flex="0 0 60px" mr={4}>
          <Image src="https://i.imgur.com/IInhVjD.png" alt="Image 1" className='htb-img'/>
        </Box>
        <Box flex="1">
          <Text>Connect Metamask Wallet On BNB Smart Chain Network,
            Make sure you have the BNB Smart Chain Network in wallet.</Text>
        </Box>
      </Flex>
      <Flex align="center" mb={4}>
        <Box flex="0 0 60px" mr={4}>
          <Image src="https://i.imgur.com/77fnOQq.png" alt="Image 2" className='htb-img'/>
        </Box>
        <Box flex="1">
        <Text>Enter BNB amount you would like to buy LBP for OR enter the amount of LBP you would like to purchase.</Text>

        </Box>
      </Flex>
      <Flex align="center">
        <Box flex="0 0 60px" mr={4}>
          <Image src="https://i.imgur.com/VQvL8PY.png" alt="Image 3" className='htb-img'/>
        </Box>
        <Box flex="1">
        <Text>Press The Buy Button To Initialize The Purchase.</Text>

        </Box>
      </Flex>
      <Flex align="center">
        <Box flex="0 0 60px" mr={4}>
          <Image src="https://i.imgur.com/9qCrPiU.png" alt="Image 4" className='htb-img'/>
        </Box>
        <Box flex="1">
        <Text>Confirm The Transaction On Metamask To Get The Coins!</Text>

        </Box>
      </Flex>
    </Box>
  );
};

export default HowToBuy;